<template>
    <Xero-Integration-Panel
        v-if="partyID == 'xero'" />
    <div v-else>Unknown</div>
</template>

<script>
export default {
    name: 'External-Party-Integration',
    components: {
        XeroIntegrationPanel: () => import('~home/external-parties/xero/Xero-Integration-Panel.vue')
    },
    computed: {
        partyID() {            
            return this.$route.params['id'];
        },

    }
}
</script>